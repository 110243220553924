import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import "./style.scss"

const Hero = ({ title, content, bgImage, right = false }) => (
  <div
    className={classNames({
      hero: true,
      "text-right": right,
    })}
    style={{ backgroundImage: `url(${bgImage})` }}
  >
    <div className="container">
      {title && (
        <div className="hero-title">
          <h1>{title}</h1>
        </div>
      )}
      {content && (
        <div className="hero-content">
          <h3>{content}</h3>
        </div>
      )}
    </div>
  </div>
)

Hero.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  right: PropTypes.bool,
}

export default Hero
