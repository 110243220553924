import React from "react"

const PageContent = () => {
  return (
    <div className="container">
      <div className="page-content">
        <p>
          In addition to our standard bending range, Inductabend also offer
          large radius induction formed pipe bends. We’re expert pipe benders
          and these bends are of superior quality when compared to bends formed
          by conventional field bending techniques.Pipeline design is no longer
          constrained by field bending limitations. Inductabend’s technologies
          offer considerable economic advantages. Higher X grades and thinner
          wall pipes can be induction bent without the risk of field bending
          difficulties compromising quality or alignment design. Because
          induction bends can be formed to much tighter radii – or even as
          compound bends – changes in grade or alignment can be achieved over
          shorter distances achieving desired alignments and minimising
          excavation and environmental impacts. Varying radius bends such as
          ellipses and spirals can also be formed.
        </p>
        <p>
          Large radius induction formed pipe bends have almost no ovality caused
          by the bending process. Like buttweld elbows, stock lengths of the
          curved pipe can be stored on site, cut to the required angle and
          directly matched to straight pipe or other curved pipe – even if
          oriented in different bending planes.
        </p>
        <p>
          Induction formed pipe bends can be factory prepared with various
          protective coatings.
        </p>
        <p>
          Custom bends are also available including helixes, offset and complex
          compound bends formed as single pipe spools.
        </p>

        <h2>Compound Bend Pipe Spools</h2>
        <p>
          Inductabend has the expertise to form compound bent pipe spools for
          complex pipe geometries using our CNC Cojafex induction bending
          machine. Of course limits exist as to the practical and cost effective
          size of compound pipe spools – please enquire as we are always willing
          to assist with your particular design requirements.
        </p>
        <p>
          Compound spools can be formed in all of the suitable pipe materials
          which include: carbon and alloy steels, high X-grade linepipe carbon
          steels, stainless steels, chrome-nickel alloys and titanium.
        </p>
        <p>
          Induction bending is an ideal method for bending duplex and other
          stainless steels. The ability to rapidly heat and cool the material in
          a single thermal cycle preserves the duplex phase ratio and prevents
          the formation of undesirable intergrannular phases. This includes the
          heat transition zones at each end of the bend where the short time
          spent at the medium temperatures is less than that required for
          initiation of any detrimental metallurgical effects. Bends may be
          formed in air with post bend pickle and passivation, or by bore
          atmosphere displacement with nitrogen.
        </p>
        <p>
          At Inductabend we are innovative and enjoy a challenge. Depending on
          the pipe and bend size, compound and offset bends can be formed
          bend-to-bend (without connecting straights), and even overlapped 360
          degree bends, spirals and coils by continuous spooling techniques.
        </p>
        <p>
          Advantages of compound pipe spools include fewer weld joints for
          increased spool integrity, process control for quality assurance,
          design flexibility and smooth flow for reduced turbulence.
        </p>
        <p>
          Feel free to contact our friendly team of pipe benders in Melbourne on{" "}
          <a href="tel:0393151244">03 9315 1244</a> for more info.
        </p>
      </div>
    </div>
  )
}

export default PageContent
